.big-quotes {
  display: inline; /* Ensures the component behaves inline */
  white-space: normal; /* Allows text wrapping */
}

.quote-start,
.quote-end {
  display: inline; /* Keep quotes inline */
  vertical-align: top; /* Align quotes with the text */
}

.big-quotes-text {
  display: inline; /* Ensure text flows normally */
  word-break: break-word; /* Allow long words to break */
  white-space: normal; /* Allow wrapping by words */
}