@tailwind base;
@tailwind components;
@tailwind utilities;


body, html {
  background-color: theme('colors.light');
  overflow-x: hidden;
}

ol,
ul {
  list-style-position: inside;
  margin-left: 0.5rem;
  /* Adjust as needed */
  padding-left: 1rem;
  /* Adjust as needed */
}

ul.list-disc>li {
  list-style: disc;
}

ol.list-decimal>li {
  list-style: decimal;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-left: 0.5rem;
  /* Adjust for nested lists */
  padding-left: 1rem;
  /* Adjust for nested lists */
}

li {
  margin-bottom: 0.5rem;
  /* Adjust space between list items */
}
