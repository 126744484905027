
.snake-text {
  display: inline; /* Keep inline behavior for wrapping */
  white-space: normal; /* Allow wrapping */
  word-break: break-word; /* Ensure words break as needed */
}

.snake-word {
  display: inline-block; /* Ensure each word is treated as a unit */
}

.snake-char {
  display: inline-block;
  /* 1.2s with a custom cubic-bezier for gentler easing */
  animation: snake-wiggle 1.4s infinite cubic-bezier(0.64, 0, 0.35, 1);
}

@keyframes snake-wiggle {
  0%, 100% { transform: translateY(1.25px); }
  25%      { transform: translateY(-1.25px); }
  50%      { transform: translateY(1.25px); }
  75%      { transform: translateY(-1.25px); }
}